import { Spinner, SpinnerSize } from '@fluentui/react';
import { Menu } from '@headlessui/react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';

import { EPackingListStatus } from '../../../enums';
import { ReactComponent as PurchaseOrderIcon } from '../../../images/icons/order.svg';
import { ReactComponent as PlusCircleIcon } from '../../../images/icons/plus-cricle.svg';
import { usePackingListDetailContext } from '../context';
import TooltipIcon from '../../../common/tooltipIcon';

interface IPackingListPurchaseOrdersProps {
    onPurchaseOrderRemove: (purchaseOrderId: string, size: string) => Promise<void>;
}

const PackingListPurchaseOrders = ({ onPurchaseOrderRemove }: IPackingListPurchaseOrdersProps) => {
    const { openAddPurchaseOrderModal, purchaseOrders, packingList, packageData } =
        usePackingListDetailContext();

    const { statusId } = packingList ?? {};
    const packedPurchaseOrderDetails = packageData?.packedPurchaseOrderDetails ?? [];

    const isCompleted =
        statusId === EPackingListStatus.CANCELLED || statusId === EPackingListStatus.CONFIRMED;

    const findPackedDetails = (poDocId: string, size: string) => {
        const packedPO = packedPurchaseOrderDetails.find((p) => p.poDocId === poDocId);
        if (!packedPO) return null;

        const packedSku = packedPO.packedSkus.find((sku) => sku.size === size);
        return packedSku || null;
    };

    return (
        <div>
            <div className="flex justify-between items-center p-4 w-full rounded-t-lg border border-gray-200">
                <div className="flex items-center gap-x-4">
                    <PurchaseOrderIcon />
                    <div>
                        <h1 className="text-gray-700 text-xl leading-8 font-bold">
                            Purchase Orders
                        </h1>
                        <p className="text-sm text-[rgba(52,64,84,0.80)]">
                            This is the ist of purchase orders that can be shipped
                        </p>
                    </div>
                </div>
                <button
                    onClick={openAddPurchaseOrderModal}
                    disabled={isCompleted}
                    className="flex gap-x-2 text-sm font-medium px-3.5 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-transparent"
                    style={{
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    }}
                >
                    <PlusCircleIcon />
                    Add Purchase Order
                </button>
            </div>
            <div className=" p-4 w-full rounded-b-lg border border-gray-200 border-t-0">
                <Table className="border-none rounded-lg">
                    <TableHead className="bg-bg-gray py-10 sticky top-[88px] z-20">
                        <TableRow>
                            <TableCell className="border-none">PO</TableCell>
                            <TableCell className="border-none">PO Date</TableCell>
                            <TableCell className="border-none">Item</TableCell>
                            <TableCell className="border-none">Color</TableCell>
                            <TableCell className="border-none">Size</TableCell>
                            <TableCell className="border-none">Order Qty.</TableCell>
                            <TableCell className="border-none">Shipped Qty.</TableCell>
                            <TableCell className="border-none">Units/Cartons</TableCell>
                            <TableCell className="border-none flex gap-1 items-center">
                                <span>Remaining Qty.</span>
                                <TooltipIcon
                                    title="Total remaining item quantity is calculated based on all
                                            confirmed packing lists including current one."
                                />
                            </TableCell>
                            {!isCompleted && <TableCell className="border-none w-5"></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseOrders.length === 0 && (
                            <TableRow>
                                <TableCell
                                    className="border-none py-20 text-center text-[28px] text-gray-500"
                                    colSpan={9}
                                >
                                    No Purchase Order yet
                                </TableCell>
                            </TableRow>
                        )}
                        {purchaseOrders.map((purchaseOrder) => {
                            const {
                                poIdToBeDisplayed,
                                poDocId,
                                size,
                                poDate,
                                style,
                                color,
                                orderQty,
                                shippedQty,
                            } = purchaseOrder;

                            const { orderQuantity, packedSkuDetails, totalRemainingQuantity } =
                                findPackedDetails(poDocId, size) ?? {};
                            const { packedPackageQuantity, packedItemQuantity } =
                                packedSkuDetails?.[0] ?? {};

                            return (
                                <TableRow key={`${poDocId}-${size}`} className="group">
                                    <TableCell className="group-last:border-none">
                                        {poIdToBeDisplayed}
                                    </TableCell>
                                    <TableCell className="group-last:border-none">
                                        {moment(poDate).format('DD MMM YYYY')}
                                    </TableCell>
                                    <TableCell className="group-last:border-none">
                                        {style}
                                    </TableCell>
                                    <TableCell className="group-last:border-none">
                                        {color}
                                    </TableCell>
                                    <TableCell className="group-last:border-none">{size}</TableCell>
                                    <TableCell className="group-last:border-none">
                                        {orderQty}
                                    </TableCell>
                                    <TableCell className="group-last:border-none">
                                        {shippedQty}
                                    </TableCell>
                                    <TableCell className="group-last:border-none">{`${
                                        packedItemQuantity ?? 0
                                    }/${packedPackageQuantity ?? 0}`}</TableCell>
                                    <TableCell className="group-last:border-none">
                                        {totalRemainingQuantity}
                                    </TableCell>
                                    {!isCompleted && (
                                        <TableCell className="group-last:border-none ">
                                            <Actions
                                                poId={poDocId}
                                                poSize={size}
                                                onPurchaseOrderRemove={onPurchaseOrderRemove}
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

const Actions = ({
    poId,
    poSize,
    onPurchaseOrderRemove,
}: {
    poId: string;
    poSize: string;
    onPurchaseOrderRemove: (purchaseOrderId: string, size: string) => Promise<void>;
}) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    disabled={deleteLoading}
                    className="w-[120px] flex justify-center items-center gap-x-2 text-[#667085] text-sm p-2.5 hover:bg-gray-100 disabled:hover:bg-transparent disabled:opacity-60 rounded-md transition"
                >
                    {deleteLoading && <Spinner size={SpinnerSize.small} />}
                    Delete
                </Menu.Button>
            </div>
            <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[40]">
                <div className="px-1 py-1">
                    <Menu.Item>
                        {() => (
                            <button
                                onClick={() => {
                                    setDeleteLoading(true);
                                    onPurchaseOrderRemove(poId, poSize);
                                }}
                                className={`hover:bg-red-500 hover:text-white transition group flex w-full items-center rounded-md px-6 py-2 text-sm`}
                            >
                                Confirm Delete
                            </button>
                        )}
                    </Menu.Item>
                </div>
            </Menu.Items>
        </Menu>
    );
};

export default PackingListPurchaseOrders;
