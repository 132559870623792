import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';

import { CountsPanel } from '../components/dashboard/countsPanel';
import { DashboardFilter } from '../components/dashboard/filter';
import { MessagesPanel } from '../components/dashboard/messagesPanel';
import { OrderListPanel } from '../components/dashboard/orderListPanel';
import { QuantityPanel } from '../components/dashboard/quantityPanel';
import { fetchDashboardInfo } from '../store/asyncActions/dashboard';
import { fetchSuppliers } from '../store/asyncActions/supplier';
import { dashboardSelector } from '../store/reducers/dashboardSlice';

export const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    const dashboardInfo = useSelector(dashboardSelector);

    React.useEffect(() => {
        dispatch(fetchDashboardInfo(dashboardInfo.filter));
    }, [dashboardInfo.filter]);

    React.useEffect(() => {
        dispatch(fetchSuppliers());
    }, []);

    return (
        <div className="bg-bg-gray pb-32">
            <DashboardFilter />
            <CountsPanel />
            <Container style={{ maxWidth: '97.5%' }} className="pt-[5px]">
                <Row>
                    <Col md={6}>
                        <QuantityPanel />
                    </Col>
                    <Col md={6}>
                        <OrderListPanel />
                        <MessagesPanel />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
