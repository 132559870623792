import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { ActionButton, DefaultButton, TextField } from '@fluentui/react';
import { addDays } from 'date-fns';
import React from 'react';
import { DateRangePicker } from 'react-date-range';
import { Container } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Panel } from '../../common/panel';
import { CustomSelect } from '../../common/select';
import { DEFAULT_DASHBOARD_FILTERS, staticRanges, textFieldStyles } from '../../consts';
import { mapSuppliers } from '../../helpers/utils';
import { IDashboardFilter } from '../../store/models.ts/dashboard';
import { dashboardSelector, dashboardSlice } from '../../store/reducers/dashboardSlice';
import { supplierSelector } from '../../store/reducers/supplierSlice';

const defualtValues: IDashboardFilter = {
    vendorId: '',
    season: '',
    delivery: '',
    expectedDeliveryFrom: '',
    expectedDeliveryTo: '',
};

const defaultDateValue = [
    {
        startDate: new Date(),
        endDate: addDays(new Date(), 3),
        key: 'selection',
    },
];

export const DashboardFilter: React.FC = () => {
    const suppliers = useSelector(supplierSelector);
    const dashboardInfo = useSelector(dashboardSelector);
    const dispatch = useDispatch();
    const [randomIndex, setRandomIndex] = React.useState(0);
    const [state, setState] = React.useState(defaultDateValue);

    const { handleSubmit, control, reset } = useForm<IDashboardFilter>({
        defaultValues: defualtValues,
        reValidateMode: 'onChange',
    });

    const [isCalendarShown, setIsCalendarShown] = React.useState({ month: 0, calendar: false });

    const calendarRef = React.useRef<HTMLElement>();

    const handleResetFilter = () => {
        reset(defualtValues);
        setRandomIndex(Math.floor(Math.random() * 6) + 1);
        dispatch(
            dashboardSlice.actions.setFilterFields({
                ...defualtValues,
                expectedDeliveryTo: DEFAULT_DASHBOARD_FILTERS.expectedDeliveryTo,
                expectedDeliveryFrom: DEFAULT_DASHBOARD_FILTERS.expectedDeliveryFrom,
            }),
        );
        setState(defaultDateValue);
    };

    const onSubmit = (data: IDashboardFilter) => {
        dispatch(
            dashboardSlice.actions.setFilterFields({
                ...DEFAULT_DASHBOARD_FILTERS,
                ...dashboardInfo.filter,
                ...data,
                expectedDeliveryTo: state[0].endDate,
                expectedDeliveryFrom: state[0].startDate,
            }),
        );
    };

    React.useEffect(() => {
        const elem = calendarRef.current?.children[0].children[1];

        const elem2 = calendarRef.current?.firstChild?.firstChild?.firstChild?.childNodes;

        elem2?.forEach((x) =>
            x.addEventListener('mouseover', () => {
                setIsCalendarShown({ month: 1, calendar: true });
            }),
        );
        elem2?.forEach((x) =>
            x.addEventListener('mouseout', () => {
                setIsCalendarShown({ month: 0, calendar: false });
            }),
        );

        elem?.lastChild?.addEventListener('mouseover', () => {
            setIsCalendarShown({ month: 1, calendar: true });
        });

        elem?.firstChild?.addEventListener('click', () => {
            setIsCalendarShown((prev) => {
                return { month: Number(Boolean(!prev.month)), calendar: !prev.calendar };
            });
        });
        dispatch(
            dashboardSlice.actions.setFilterFields({
                ...defualtValues,
                expectedDeliveryTo: DEFAULT_DASHBOARD_FILTERS.expectedDeliveryTo,
                expectedDeliveryFrom: DEFAULT_DASHBOARD_FILTERS.expectedDeliveryFrom,
            }),
        );
        setState(defaultDateValue);
    }, []);

    return (
        <Panel>
            <Container style={{ maxWidth: '97.5%' }} className="pt-[25px] pb-[25px] ml-[14px]">
                <form
                    className="flex items-center justify-between"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div>
                        <span className="text-[20px]">
                            <b>Expected delivery</b>
                        </span>
                        <div className="button-group mt-[10px]" ref={calendarRef as any}>
                            <DateRangePicker
                                onChange={(item) => {
                                    setState([item.selection] as any);
                                    setIsCalendarShown({ month: 1, calendar: true });
                                }}
                                moveRangeOnFirstSelection={true}
                                months={isCalendarShown.month}
                                ranges={state}
                                direction="horizontal"
                                inputRanges={[] as any}
                                staticRanges={staticRanges}
                                showMonthAndYearPickers={isCalendarShown.calendar}
                                showMonthArrow={isCalendarShown.calendar}
                            />
                        </div>
                    </div>
                    <div>
                        <span className="text-[20px]">
                            <b>Vendor</b>
                        </span>
                        <Controller
                            control={control}
                            name="vendorId"
                            render={({ field: { value, onChange } }) => (
                                <CustomSelect
                                    options={mapSuppliers(suppliers.data) as any}
                                    placeholder="Select an option"
                                    width={200}
                                    color="black"
                                    borderColor="#e6e6e6"
                                    onChange={onChange}
                                    value={value}
                                    dropdownHeight={500}
                                    className="mt-[10px]"
                                    key={randomIndex}
                                    defaultValue={
                                        mapSuppliers(suppliers.data).length === 2
                                            ? mapSuppliers(suppliers.data)[1].key
                                            : undefined
                                    }
                                />
                            )}
                        />
                    </div>
                    <div>
                        <span className="text-[20px]">
                            <b>Season</b>
                        </span>
                        <Controller
                            control={control}
                            name="season"
                            render={({ field: { value, onChange } }) => (
                                <TextField
                                    value={value}
                                    onChange={onChange}
                                    className="filter_border_style mt-[10px]"
                                    styles={textFieldStyles}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <span className="text-[20px]">
                            <b>Delivery</b>
                        </span>
                        <Controller
                            control={control}
                            name="delivery"
                            render={({ field: { value, onChange } }) => (
                                <TextField
                                    value={value}
                                    onChange={onChange}
                                    className="filter_border_style mt-[10px]"
                                    styles={textFieldStyles}
                                />
                            )}
                        />
                    </div>
                    <DefaultButton
                        text="Search"
                        allowDisabledFocus
                        type="submit"
                        className="mt-[25px]"
                        onClick={handleSubmit(onSubmit)}
                    />
                    <div className="flex items-center mt-[25px]">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <ActionButton
                            className="underline p-0 whitespace-nowrap"
                            onClick={handleResetFilter}
                        >
                            Clear filters
                        </ActionButton>
                    </div>
                </form>
            </Container>
        </Panel>
    );
};
