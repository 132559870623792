import { ChevronDownIcon, ChevronRightIcon } from '@fluentui/react-icons-mdl2';
import { Checkbox, IconButton, TableCell, TableRow } from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';
import moment from 'moment';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { ROUTES } from '../../../../consts';
import { useExpose } from '../../../../helpers/hooks';
import { groupByObjArrayByField, handleIfCheckBoxChecked } from '../../../../helpers/utils';
import p30 from '../../../../images/27x28.png';
import { IOrderElems } from '../../../../store/models.ts/common';
import { ICheckedValues } from '../../../../store/models.ts/ProductionStages';
import { useOrderContext } from '../../context/orderContext';
import { AdvancedViewFields } from './advancedViewFields';
import { CollapseRow } from './collapseRow';

interface IProps {
    order: IOrderElems;
    mutateStatuses: UseMutateFunction<any, any, ICheckedValues, unknown>;
}

export const CollapsableProductionTableRow: React.FC<IProps> = ({ order, mutateStatuses }) => {
    const { toggle: toggleCollapsedRow, isOpen: isCollapsedRowOpen } = useExpose();
    const { checkedRows, setCheckedRows, isAdvancedActive } = useOrderContext();

    const handleCheckChanges = () => {
        if (checkedRows.find((id) => id === order.document.poDocId)) {
            setCheckedRows((prev) => prev.filter((id) => id !== order.document.poDocId));
        } else {
            setCheckedRows((prev) => [...prev, order.document.poDocId]);
        }
    };
    const grouppedOrderPreProdStagesByCategory = useMemo(
        () => groupByObjArrayByField(order.document.orderPreProductionStages ?? [], 'categoryId'),
        [order.document],
    );

    return (
        <>
            <TableRow
                key={order.document.poDocId}
                className={`t-row pr-4 parent-row mt-2 ${
                    handleIfCheckBoxChecked(checkedRows, order.document.poDocId) && 'bg-orange-50'
                }`}
            >
                {!isAdvancedActive && (
                    <TableCell className="pl-[25px] border-none">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => toggleCollapsedRow(false)}
                        >
                            {isCollapsedRowOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </TableCell>
                )}
                <TableCell
                    className={`${
                        isAdvancedActive && 'border-r-2 shadowy '
                    } sticky left-0 border-none pl-0 bg-white`}
                >
                    <TableCell padding="checkbox" className="border-none">
                        <Checkbox
                            color="primary"
                            indeterminate={false}
                            checked={handleIfCheckBoxChecked(checkedRows, order.document.poDocId)}
                            onChange={handleCheckChanges}
                        />
                    </TableCell>
                    <TableCell className="border-none min-w-[150px]">
                        {order.document.seasonDelivery}
                    </TableCell>
                    <TableCell className="border-none min-w-[130px]">
                        <NavLink
                            to={`../${ROUTES.ORDER_DETAILS}/${order.document.poDocId}?isMaster=true`}
                            className="underline"
                        >
                            {order.document.orderIdToBeDisplayed}
                        </NavLink>
                    </TableCell>
                    <TableCell className="border-none min-w-[170px]">
                        <div className="flex items-center">
                            <img src={p30} className="mr-[5px]" alt="photo" />
                            <p className="mr-3">{order.document.style}</p>
                        </div>
                    </TableCell>
                    <TableCell className="border-none whitespace-normal min-w-[160px]">
                        {order.document.colorDescription}
                    </TableCell>
                </TableCell>
                <TableCell className="border-none whitespace-normal min-w-[200px]">
                    {order.document.styleDesc}
                </TableCell>
                {isAdvancedActive && (
                    <TableCell className="border-none whitespace-normal min-w-[200px]">
                        {order.document.styleType ?? '-'}
                    </TableCell>
                )}
                <TableCell className="border-none">{order.document.quantity}</TableCell>
                <TableCell className="border-none">
                    {moment(order.document.expectedDepartureDate).format('MM-DD-YYYY')}
                </TableCell>
                <TableCell className="border-none">
                    {moment(order.document.expectedDeliveryDate).format('MM-DD-YYYY')}
                </TableCell>
                <TableCell className="border-none">
                    <div className="flex items-center">
                        <span className="mr-2">
                            {order.document.productionStatusCompleted}/
                            {order.document.productionStatusTotal}
                        </span>
                        <div className="flex items-center">
                            <div
                                className={`dot mr-3 p-2 ${
                                    order.document.productionStatusCompleted ===
                                    order.document.productionStatusTotal
                                        ? 'bg-hard-green'
                                        : 'bg-hard-blue'
                                }`}
                            ></div>
                            {order.document.productionStageDs}
                        </div>
                    </div>
                </TableCell>
                <TableCell className="border-none">
                    <div
                        className={`chip ${
                            order.document.daysToDelivery <= 0
                                ? 'bg-estimate-danger'
                                : order.document.productionStatusCompleted ===
                                  order.document.productionStatusTotal
                                ? 'bg-soft-green'
                                : 'bg-estimate-normal'
                        }`}
                    >
                        <div className="el pr-3 pl-3">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 mr-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            {order.document.productionStatusCompleted ===
                            order.document.productionStatusTotal
                                ? 'Delivered'
                                : `${order.document.daysToDelivery} days left`}
                        </div>
                    </div>
                </TableCell>
                <TableCell className="border-none whitespace-normal min-w-[200px]">
                    {order.document.vendorDs}
                </TableCell>
                {!isAdvancedActive && (
                    <>
                        <TableCell align="center" className="border-none">
                            <p>{order.document.destination}</p>
                        </TableCell>
                        {grouppedOrderPreProdStagesByCategory.map((stages: any) => (
                            <TableCell key={stages[0].categoryId}>
                                <div
                                    className={`dot ml-2 items-center ${
                                        stages[0].isCategoryCompleted
                                            ? 'bg-hard-green'
                                            : 'bg-hard-gray'
                                    }`}
                                ></div>
                            </TableCell>
                        ))}
                    </>
                )}
                <AdvancedViewFields order={order} />
            </TableRow>
            <CollapseRow
                order={order}
                isRowOpen={isCollapsedRowOpen}
                mutateStatuses={mutateStatuses}
            />
        </>
    );
};
